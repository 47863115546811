var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_vm._m(0),_c('div',{staticClass:"container container-resize--2"},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"login__form mb-5"},[_c('h2',{staticClass:"login__title"},[_vm._v("Login")]),_c('ValidationObserver',{ref:"login",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.SubmitForm)}}},[_c('div',{staticClass:"container container-resize"},[_c('div',{staticClass:"form-group mt-5"},[_c('label',{staticClass:"login__label",attrs:{"for":"user"}},[_vm._v("Username*")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.login.login),expression:"login.login",modifiers:{"trim":true}}],staticClass:"form-control login__input",attrs:{"type":"text","id":"user","placeholder":"name@example.com"},domProps:{"value":(_vm.login.login)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.login, "login", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group mt-3"},[_c('label',{staticClass:"login__label",attrs:{"for":"password"}},[_vm._v("Password*")]),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.login.password),expression:"login.password",modifiers:{"trim":true}}],staticClass:"form-control login__input",attrs:{"type":"password","id":"password","placeholder":"password"},domProps:{"value":(_vm.login.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.login, "password", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-restyled"},[_c('p',[_vm._v("Forget password?")]),_c('div',{staticClass:"form-check check-restyled"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","value":"","id":"flexCheckDefault"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"flexCheckDefault"}},[_vm._v("Remember me")])])]),_c('button',{staticClass:"btn__registration btn-login mt-5 mb-3"},[_vm._v("login")]),_c('h6',{staticClass:"h-1"},[_vm._v("Recover you password: "),_c('a',{staticClass:"h6-2",staticStyle:{"cursor":"pointer"},on:{"click":_vm.Register}},[_vm._v("Forgot Password")])])])])]}}])})],1)]),_vm._m(2)])]),_vm._m(3)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login__logo"},[_c('img',{attrs:{"src":"images/logo.png","alt":"logo"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h1',{staticClass:"welcome welcome-small"},[_vm._v("welcome")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-8"},[_c('h1',{staticClass:"welcome"},[_vm._v("welcome")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"welcome__icon"},[_c('img',{staticClass:"img-1",attrs:{"src":"images/group-2.svg","alt":"user"}}),_c('img',{attrs:{"src":"images/group-1.svg","alt":"chat"}})])}]

export { render, staticRenderFns }