<template>
      <div class="login">
          <div class="login__logo">
              <img src="images/logo.png" alt="logo">
          </div>

          <div class="container container-resize--2">
              <div class="row">
                  <div class="col-md-4">
                      <h1 class="welcome welcome-small">welcome</h1>
                  </div>
              </div>

              <div class="row">
                  <div class="col-md-4">
                      <div class="login__form mb-5">
                          <h2 class="login__title">Login</h2>
                        <ValidationObserver ref="login" v-slot="{handleSubmit}">
                            <form @submit.prevent="handleSubmit(SubmitForm)">
                              <div class="container container-resize">
                                <div class="form-group mt-5">
                                  <label for="user" class="login__label">Username*</label>
                                  <ValidationProvider
                                      name="name"
                                      rules="required|email"
                                      v-slot="{valid, errors }"
                                  >
                                    <input type="text"
                                           class="form-control login__input" id="user"
                                           v-model.trim="login.login"
                                           placeholder="name@example.com">
                                    <div class="errors">{{errors[0]}}</div>
                                  </ValidationProvider>
                                </div>

                                <div class="form-group mt-3">
                                  <label for="password" class="login__label">Password*</label>
                                  <ValidationProvider
                                      name="password"
                                      rules="required"
                                      v-slot="{valid, errors }"
                                  >
                                    <input type="password"
                                           class="form-control login__input"
                                           id="password"
                                           v-model.trim="login.password"
                                           placeholder="password">
                                    <div class="errors">{{errors[0]}}</div>
                                  </ValidationProvider>
                                </div>

                                <div class="form-restyled">
                                  <p>Forget password?</p>
                                  <div class="form-check check-restyled">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">Remember me</label>
                                  </div>
                                </div>

                                <button class="btn__registration btn-login mt-5 mb-3" >login</button>
                                <h6 class="h-1">Recover you password: <a class="h6-2" style="cursor: pointer" @click="Register">Forgot Password</a></h6>
<!--                                <h6 class="h6-1">Not a member? <h6 class="h6-2" @click="Register" style="cursor: pointer">Register</h6></h6>-->
                              </div>
                            </form>
                        </ValidationObserver>
                      </div>
                  </div>

                  <div class="col-md-8">
                      <h1 class="welcome">welcome</h1>
                  </div>
              </div>
          </div>
          <div class="welcome__icon">
              <img src="images/group-2.svg" alt="user" class="img-1">
              <img src="images/group-1.svg" alt="chat">
          </div>
      </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
export default {
  name: 'Home',
  data () {
    return {
      login: {},
    }
  },
  methods: {
    ...mapActions({
      AuthLogin: 'auth/AuthLogin',
    }),
    ...mapMutations({
      Loading: 'setup/loading'
    }),
    async SubmitForm () {
      const isValid = this.$refs.login.validate()
      if (!isValid){
        this.$toastr.w('Input not entered properly')
        return false
      }
      try {
        const formDate = new FormData
        formDate.append('login', this.login.login)
        formDate.append('password', this.login.password)
        this.Loading(true)
        const response = await this.AuthLogin(formDate)
        let vm = this
        if (response.status === 200){
          vm.$toastr.s(this.message)
          setTimeout(function () {
            vm.Loading(false)
            vm.$router.push('/dashboard')
          }, 200)
        } else {
          this.Loading(false)
          this.$toastr.w(this.errors)
        }
      }catch (e) {
        this.Loading(false)
        this.$toastr.e(this.errors)
      }
    },
    Register () {
      this.$router.push('/forgot-password')
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      errors: 'setup/errors',
      message: 'setup/message',
      isToggle: 'setup/isToggle',
    }),
  },
}
</script>
<style scoped>

</style>
